exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blogs-[id]-[slug]-js": () => import("./../../../src/pages/blogs/[id]/[slug].js" /* webpackChunkName: "component---src-pages-blogs-[id]-[slug]-js" */),
  "component---src-pages-blogs-blog-single-js": () => import("./../../../src/pages/blogs/blogSingle.js" /* webpackChunkName: "component---src-pages-blogs-blog-single-js" */),
  "component---src-pages-blogs-index-jsx": () => import("./../../../src/pages/blogs/index.jsx" /* webpackChunkName: "component---src-pages-blogs-index-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-aerospace-jsx": () => import("./../../../src/pages/industries/aerospace.jsx" /* webpackChunkName: "component---src-pages-industries-aerospace-jsx" */),
  "component---src-pages-industries-agriculture-jsx": () => import("./../../../src/pages/industries/agriculture.jsx" /* webpackChunkName: "component---src-pages-industries-agriculture-jsx" */),
  "component---src-pages-industries-automotive-jsx": () => import("./../../../src/pages/industries/automotive.jsx" /* webpackChunkName: "component---src-pages-industries-automotive-jsx" */),
  "component---src-pages-industries-bfsi-jsx": () => import("./../../../src/pages/industries/bfsi.jsx" /* webpackChunkName: "component---src-pages-industries-bfsi-jsx" */),
  "component---src-pages-industries-healthcare-jsx": () => import("./../../../src/pages/industries/healthcare.jsx" /* webpackChunkName: "component---src-pages-industries-healthcare-jsx" */),
  "component---src-pages-industries-manufacturing-jsx": () => import("./../../../src/pages/industries/manufacturing.jsx" /* webpackChunkName: "component---src-pages-industries-manufacturing-jsx" */),
  "component---src-pages-industries-medical-devices-jsx": () => import("./../../../src/pages/industries/medical-devices.jsx" /* webpackChunkName: "component---src-pages-industries-medical-devices-jsx" */),
  "component---src-pages-industries-retail-jsx": () => import("./../../../src/pages/industries/retail.jsx" /* webpackChunkName: "component---src-pages-industries-retail-jsx" */),
  "component---src-pages-industries-semiconductors-jsx": () => import("./../../../src/pages/industries/semiconductors.jsx" /* webpackChunkName: "component---src-pages-industries-semiconductors-jsx" */),
  "component---src-pages-onboarding-jsx": () => import("./../../../src/pages/onboarding.jsx" /* webpackChunkName: "component---src-pages-onboarding-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-products-loopr-ai-store-jsx": () => import("./../../../src/pages/products/loopr-ai-store.jsx" /* webpackChunkName: "component---src-pages-products-loopr-ai-store-jsx" */),
  "component---src-pages-products-loopr-edge-jsx": () => import("./../../../src/pages/products/loopr-edge.jsx" /* webpackChunkName: "component---src-pages-products-loopr-edge-jsx" */),
  "component---src-pages-products-loopriq-inspect-jsx": () => import("./../../../src/pages/products/loopriq-inspect.jsx" /* webpackChunkName: "component---src-pages-products-loopriq-inspect-jsx" */),
  "component---src-pages-products-loopriq-verify-jsx": () => import("./../../../src/pages/products/loopriq-verify.jsx" /* webpackChunkName: "component---src-pages-products-loopriq-verify-jsx" */),
  "component---src-pages-solutions-defect-detection-jsx": () => import("./../../../src/pages/solutions/defect-detection.jsx" /* webpackChunkName: "component---src-pages-solutions-defect-detection-jsx" */),
  "component---src-pages-solutions-part-assembly-jsx": () => import("./../../../src/pages/solutions/part-assembly.jsx" /* webpackChunkName: "component---src-pages-solutions-part-assembly-jsx" */),
  "component---src-pages-solutions-part-identification-jsx": () => import("./../../../src/pages/solutions/part-identification.jsx" /* webpackChunkName: "component---src-pages-solutions-part-identification-jsx" */),
  "component---src-pages-solutions-smart-gauges-jsx": () => import("./../../../src/pages/solutions/smart-gauges.jsx" /* webpackChunkName: "component---src-pages-solutions-smart-gauges-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

